import React, { memo, useCallback, useContext, useMemo, useEffect } from 'react'

import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'

import useScroll from '$hooks/useScroll/useScroll'

import HeaderMenuService, {
  HeaderContext,
} from '$services/HeaderMenuService/HeaderMenuService'
import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import AkzonobelDesk from './_images/akzonobel-desk.svg?component'
import Akzonobel from './_images/akzonobel.svg?component'
import HeaderLogo from './HeaderParts/HeaderLogo'
import HeaderMenu from './HeaderParts/HeaderMenu'
import HeaderSign from './HeaderParts/HeaderSign'

const headerBlockClasses = classNames('header-container header__block')
const headerLogoClasses = classNames('header-logo-block')
const headerBurgerClasses = classNames('header-burger')

const Header: React.FC = () => {
  const fixedHeader = useAppSelector((store) => store.config.site?.header_fixed)

  const { isTablet } = useContext(ViewportContext)
  const { isOpenMenu, setOpenMenuState } = useContext(HeaderContext)

  useScroll(isOpenMenu)

  const headerMenuClasses = useMemo(
    () =>
      classNames('header-menu', {
        'header-menu_open': isOpenMenu,
      }),
    [isOpenMenu],
  )

  const openMenu = useCallback(() => {
    setOpenMenuState((isOpen) => !isOpen)
  }, [setOpenMenuState])

  useEffect(() => {
    if (!isTablet && isOpenMenu) {
      setOpenMenuState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet])

  const dispatch = useDispatch()

  const { query } = useRouter()
  const popupId = query['popupid']

  useEffect(() => {
    if (!sessionStorage.getItem('extension') && !popupId) {
      dispatch(
        showPopup({
          title: 'Уважаемые участники программы «С\u00A0нами\u00A0выгодно!»',
          message: `<p><br />
      Согласно Правилам Программы, период её проведения включая период выдачи
      Поощрений был активен по&nbsp;<b>23:59&nbsp;(мск) «31»&nbsp;июля&nbsp;2024&nbsp;года.</b>
      <br />
      <br />
      Для зарегистрированных участников личный кабинет доступен только для&nbsp;просмотра данных.
      <br />
      <br />
      После окончания Периода выдачи Поощрений все неполученные Поощрения он&nbsp;может получить в период регистрации Участников Программы (волна 2), а&nbsp;именно с&nbsp;<b>00:00:01 ч. «01»&nbsp;октября&nbsp;2024г. по&nbsp;23:59:59&nbsp;ч. «10»&nbsp;октября&nbsp;2024г.</b>
      <br />
      <br />
      При возникновении вопросов – пишите на&nbsp;почту
      <a href="mailto:info@promo-akzonobel.ru" rel="noreferrer noopener">
        info@promo-akzonobel.ru
      </a>
    </p>`,
        }),
      )
      sessionStorage.setItem('extension', 'true')
    }
  }, [])

  return (
    <header className={classNames('header', { fixed: fixedHeader })}>
      <div className={headerBlockClasses}>
        <div className={headerLogoClasses}>
          <HeaderLogo />
        </div>
        <div className={headerMenuClasses}>
          <HeaderMenu />
        </div>
        {isTablet && (
          <div className="burger-container">
            {isOpenMenu ? (
              <div onClick={openMenu} className="close-burger" />
            ) : (
              <div onClick={openMenu} className="burger-wrapper">
                <div className={headerBurgerClasses} />
              </div>
            )}
          </div>
        )}
        <div className="akzonobel">
          <a
            href="https://www.akzonobel.com/en"
            target="_blank"
            rel="noreferrer"
          >
            {isTablet ? <Akzonobel /> : <AkzonobelDesk />}
          </a>
        </div>
        <div className="sign-in-block">
          <HeaderSign />
        </div>
      </div>
    </header>
  )
}

// eslint-disable-next-line react/display-name
const HeaderWithMenuContext = memo(() => (
  <HeaderMenuService>
    <Header />
  </HeaderMenuService>
))

export default HeaderWithMenuContext
