import { useDispatch } from 'react-redux'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import { showPopup } from '$store/slices/popup'

import useFormSubmitHandler from '$hooks/useFormSubmitHandler/useFormSubmitHandler'
import useValidators from '$hooks/useValidators/useValidators'

import DrawCanvasField from '$components/form/fields/formikField/field/DrawCanvas'
import FormikConstructor from '$components/form/formik/constructor/FormConstructor/formikConstructor'
import validatorCombiner from '$components/form/validators/validatorCombiner'

const TaxConsentForm = () => {
  const initialValues = { consent: null }
  const dispatch = useDispatch()
  const { popupData } = useAppSelector((store) => store.popup.popupConfig)
  const sendConsent: any = useFormSubmitHandler<typeof initialValues>(
    [],
    (value) => {
      const data = new FormData()
      data.append('signature', value.consent ?? '')
      data.append('receipt_id', popupData.receiptId)
      return pbApi.signReceipt(data).then(
        ({
          data: {
            receipt: { signed_pdf },
          },
        }) => {
          if (popupData.isClaimed) {
            pbApi.receivePromotionOrder(popupData.orderId).then(
              ({
                data: {
                  order: {
                    product_payload: { provse_link },
                  },
                },
              }) => {
                dispatch(
                  showPopup({
                    popupId: 'receipt',
                    popupData: {
                      orderId: popupData.orderId,
                      receiptLink: signed_pdf,
                      isSigned: true,
                      provse_link,
                      ...popupData,
                    },
                  }),
                )
              },
            )
          } else {
            pbApi
              .claimReward(popupData.rewardId)
              .then(({ data: { reward } }) => {
                dispatch(
                  showPopup({
                    popupId: 'receipt',
                    popupData: {
                      orderId: popupData.orderId,
                      receiptLink: signed_pdf,
                      certificateLink: reward?.certificate_link,
                      isSigned: true,
                      ...popupData,
                    },
                  }),
                )
              })
          }
        },
      )
    },
  )

  const { isRequired } = useValidators()

  return (
    <FormikConstructor
      name="feedback"
      onSubmit={sendConsent}
      initialValues={initialValues}
      dataTest="send-feedback-button"
      direction="consent"
    >
      <DrawCanvasField
        validate={validatorCombiner([isRequired])}
        name="consent"
      />
    </FormikConstructor>
  )
}

export default TaxConsentForm
