import AuthFieldNames from '$constants/common/forms/auth/fields/AuthFieldsName'

import { MainConfigType } from '$store/slices/config/types'
import { WinnerType } from '$store/slices/config/types/draw/drawType'
import { ConfigPromo } from '$store/slices/config/types/promo/promo.types'
import { RewardOrder } from '$store/slices/config/types/rewards'
import { UserType } from '$store/slices/config/types/user/userType'

import {
  NewsCategoryType,
  NewsItemType,
  NewsTagType,
} from '$/types/newsTypes/newsTypes'

import pbAxios from './instance'

const pbApi = {
  factsModeration: (outlet_target_id: number, body: any) =>
    pbAxios.post(
      `/services/motivational_program/target/${outlet_target_id}`,
      body,
    ),
  receivePromotionOrder: (order_id: number) =>
    pbAxios.post<{ order: RewardOrder }>('/services/reward/order/receive', {
      order_id,
    }),
  claimReward: (reward_id: number) =>
    pbAxios.post<{
      reward: {
        id: number
        value: number
        user_id: number
        status: string
        prize_date: null
        certificate_type: null
        certificate_link: string
        certificate_code: null
        certificate_password: null
        service_payload: {
          month: string
          outlet_id: string
        }
      }
    }>('/services/auth/reward/claim', { reward_id }),

  rewardOutlet: (month: string, year: string, outlet_id: string) =>
    pbAxios.post<{
      reward: {
        pdf: string
        created_at: string
        id: number
        service_payload: any
        user_id: number
        value: number
      }
    }>('/services/auth/reward/fetch', { month, outlet_id, year }),
  getReceipt: (order_id: number) =>
    pbAxios.get<{
      receipt: {
        created_at: string
        id: number
        pdf: string
        reward_id: number
        sign: any
        signed_pdf: any
      }
    }>(`/services/auth/receipt/${order_id}`),
  getOrderReceipt: (orderId: number) =>
    pbAxios.get(`/services/auth/receipt/order/${orderId}`),
  signReceipt: (data: FormData) =>
    pbAxios.post('/services/auth/receipt/sign', data),
  setUserAdult: (data: { is_adult: boolean; birthday?: string }) =>
    pbAxios.put('/set-user-adult', data),
  confirmInviteCode: <T>(value: T) =>
    pbAxios.post('/services/auth/check-motivational-invite-code', value),
  // PERSONAL DATA
  uploadPersonalData: <T>(value: T) =>
    pbAxios.post('/services/personal-info', value),
  // UPLOAD PROMOCODE
  uploadPromoCode: (value: { code: string }) =>
    pbAxios.post('/services/promocode/code', value),
  // UPLOAD CHECK
  manualUploadCheck: <T>(value: T) =>
    pbAxios.post('/services/checks/raw', value),
  photoUploadCheck: (value: FormData) =>
    pbAxios.post('/services/checks/photos', value),
  confirmPhoneAlter: (phone: string) =>
    pbAxios.post('/services/auth/phone-verification/check-status', {
      phone,
    }),
  sendConfirmPhoneWithAlternate: (phone: string) =>
    pbAxios.post('/services/auth/phone-verification/newtel/call-password-id', {
      phone,
    }),
  qrUploadCheck: (value: string) =>
    pbAxios.post('/services/checks/qr', { qr: value }),
  // REVIEW
  sendReviewMessage: <T>(value: T) => pbAxios.post('/services/review', value),
  // FEEDBACK
  sendFeedBack: <T>(value: T) => pbAxios.post('/services/feedback', value),
  // AUTH
  signIn: <T>(authFields: T) =>
    pbAxios.post<{ token: string; user: UserType }>(
      '/services/auth/sign-in',
      authFields,
    ),
  // BASE AUTH
  checkBaseAuth: (data: { login: string; password: string }) =>
    pbAxios.post('/check-basic-auth', data),

  signUp: <T>(regFields: T) =>
    pbAxios.post('/services/auth/sign-up', { ...regFields }),
  logout: () => pbAxios.post('/services/auth/logout'),
  changeData: (value: any) => pbAxios.post('/services/auth/save-info', value),
  confirmPhone: (code: string) =>
    pbAxios.post('/services/auth/phone-verification/check', { code }),
  confirmEmail: (token: string) =>
    pbAxios.post('/services/auth/email-verification', { token }),
  sendConfirmEmail: (email: string) =>
    pbAxios.post('/services/auth/send-confirm-email', { email }),
  sendConfirmPhone: (phone: string) =>
    pbAxios.post('/services/auth/phone-verification/send', { phone }),
  restorePassword: ({ email }: { email: string }) =>
    pbAxios.post('/services/auth/restore-password', { email }),
  changePassword: (value: {
    [AuthFieldNames.password]: string
    ['password_confirmation']: string
  }) => pbAxios.post('/services/auth/change-password', value),
  newPassword: (value: {
    [AuthFieldNames.password]: string
    ['password_confirmation']: string
    ['token']: string
  }) => pbAxios.post('/services/auth/new-password', value),
  // CONFIG
  getFullConfig: () =>
    pbAxios.get<{ data: MainConfigType }>('/services/config'),
  getPromoConfig: () =>
    pbAxios.get<{ data: ConfigPromo }>('/services/promo/config'),
  getPhoneCode: (phone: string) =>
    pbAxios.post('/services/auth/phone-verification/send', {
      phone,
    }),

  getNews: async () => pbAxios.get<NewsItemType[]>('/services/news-blog/news'),
  getArticle: async (news: string, category: string) => {
    const decodeCategory = encodeURI(category)
    const decodeName = encodeURI(news)
    return pbAxios.get<NewsItemType>(
      `/services/news-blog/news/${decodeCategory}/${decodeName}`,
    )
  },
  getCategories: () =>
    pbAxios.get<NewsCategoryType[]>('/services/news-blog/categories'),
  getTags: () => pbAxios.get<NewsTagType[]>('/services/news-blog/tags'),

  checkToken: (payload: { type: 'email' | 'password'; token: string }) =>
    pbAxios.post<{ valid: boolean }>('services/auth/check-token', payload),

  // WINNERS
  searchWinners: (term: string) =>
    pbAxios.get<WinnerType>(`/api/services/draw/winner?term=${term}`),
}

export default pbApi
