import { PropsWithChildren, useEffect } from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { useAppSelector } from '$store/hooks'

import useFingerprint from '$hooks/useFingerprint'

import ViewportService from '$services/ViewportServices/ViewportServices'

import Favicon from '$components/Favicon'
import BodyHtml from '$components/helpers/html/BodyHtml'
import HeadHtml from '$components/helpers/html/HeadHtml'
import MadeWithPromoBuilding from '$components/helpers/html/MadeWithPromobuilding'
import PromoTrap from '$components/helpers/trap/PromoTrap'
import PopupManagement from '$components/popups/PopupManagement'

import MetricsWrapper from '$/other/metrix/MetricsWrapper'
import VKPixel from '$/other/VKPixel/VKPixel'

type MainAppProps = PropsWithChildren

export default function MainApp({ children }: MainAppProps) {
  const setFingerprint = useFingerprint()

  useEffect(() => {
    setFingerprint()
  }, [setFingerprint])

  const recaptcha = useAppSelector((store) => store.config.site?.recaptcha)

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        recaptcha?.version === 'v3' ? recaptcha?.site_key ?? '' : ''
      }
    >
      <HeadHtml />
      <Favicon />
      <PopupManagement />
      <PromoTrap>
        <ViewportService>{children}</ViewportService>
      </PromoTrap>
      <BodyHtml />
      <MadeWithPromoBuilding />
      <MetricsWrapper />
      <VKPixel />
    </GoogleReCaptchaProvider>
  )
}
