import React from 'react'

import Image from 'next/image'

import TitleImage from '../_images/title.svg'
import MenuNavLink from '../MenuNavLink'

const HeaderLogo: React.FC = () => (
  <div className="logo-container">
    <MenuNavLink to="/" scroll isLogoLink>
      <Image
        src={TitleImage}
        alt="Выгода в банке"
        quality={100}
        width={694}
        height={88}
      />
    </MenuNavLink>
  </div>
)

export default HeaderLogo
